<template>
  <div class="home row">
    <div v-show="getAlertShow" class="col-8 offset-2">
      <Alert
        :color="getAlert.color"
        :dismissible="true"
        :icon-name="getAlert.icon"
        :default="null"
        @clicked="closeAlert"
      >
      {{getAlert.message}}
      </Alert>
    </div>
    <div class="col-8 offset-2">
      <PageHeaderMain
        :title="pageheader.title"
        :pre-title="pageheader.preTitle"
        tab-id="userTabs"
        tab-position="left"
        tab-size="md"
        :tab-items="tabList"
        @clicked="onClicked($event)"
      />
    </div>
    <div class="col-8 offset-2">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { PageHeaderMain } from '@cendyn/cendyn-frontend-kit';
import Alert from '../components/Alert';

export default {
  name: 'Home',
  components: {
    PageHeaderMain, Alert
  },
  data () {
    return {
      pageheader: {
        title: 'Account',
        preTitle: 'Settings'
      }
    };
  },
  computed: {
    ...mapGetters('currentUser', [
      'getUserToken',
      'getUserInfo'
    ]),
    ...mapGetters('alerts', [
      'getAlertShow',
      'getAlert'
    ]),
    tabList() {
      let tabs = [{title: 'General'}];
      //Tabs Removed Preferences #353324 => {title: "Preferences"}
      if (!this.getUserInfo.ADUser) {
        tabs.push({
          title: 'Security'
        });
      }
      return tabs;
    }
  },
  async mounted() {
    await this.fetchCountryCurrencies();
    await this.fetchDateFormats();
    await this.fetchTimeZones();
    await this.fetchLanguages();
  },
  methods: {
    ...mapActions('cendynRegistry', [
      'fetchCountryCurrencies',
      'fetchDateFormats',
      'fetchLanguages',
      'fetchTimeZones'
    ]),
    closeAlert() {
      this.$store.state.alerts.alertshow = false;
    },
    onClicked(event) {
      if (event === 'userTabs-tab-0') {
        this.$router.push({ name: 'User' });
      }
      if (event === 'userTabs-tab-1') {
        this.$router.push({ name: 'Security' });
      }
      if (event === 'userTabs-tab-2') {
        this.$router.push({ name: 'Preferences' });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .alert {
    margin-top: 36px;
    margin-bottom: 0;
  }
</style>
