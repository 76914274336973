<template>
  <div :class="['alert alert-' + color + ' alert-dismissible']" role="alert">
    <BaseIcon
      v-if="hasIcon"
      :icon-name="iconName"
      :size="18"
      :viewBox="'0 0 24 24'"
    />
    <slot/>
    <button
      v-if="dismissible"
      type="button"
      class="close"
      aria-label="Close"
      @click="clicked($event)"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
import { BaseIcon } from '@cendyn/cendyn-frontend-kit';
export default {
  name: 'Alert',
  components: {
    BaseIcon
  },
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    dismissible: {
      type: Boolean,
      default: false
    },
    iconName:{
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    hasIcon() {
      return this.iconName || !this.iconName.length === 0;
    }
  },
  methods: {
    clicked(e) {
      this.$emit('clicked', e);
    }
  }
};
</script>

<style></style>
